import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirect = () => {
    setIsLoading(true);
    setTimeout(() => {
      window.location.href = 'https://app.youforever.com.br';
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src="icon.png" alt="YouForever" />
          <h1>YouForever</h1>
          <button className="access-button" onClick={handleRedirect}>
            Acesse o App
          </button>
        </header>
        <main>
          <section id="about" className="section-about">
            <h2>Bem vindo ao YouForever - Virtual Cemitery</h2>
            <p>
              Imagine um espaço onde a memória dos seus entes queridos pode ser eternizada, onde a presença daqueles que partiram continua viva e acessível, não apenas para você, mas para todos que os amaram. <br />
              Nosso aplicativo foi desenvolvido com carinho e responsabilidade para oferecer um lugar especial de homenagem e recordação, permitindo que famílias, amigos e fãs possam se conectar, mesmo à distância, num tributo digital que respeita a importância das despedidas e a preservação das memórias.<br /><br />

              Aqui, poderá participar de velórios virtuais, um espaço de união e apoio, onde o amor transcende a barreira do tempo.<br />
              Além disso, terá a oportunidade de criar o Meu Jazigo, um memorial personalizado onde pode incluir familiares, amigos e até ídolos que marcaram a sua vida.<br />
              Este jazigo virtual não é apenas um lugar de recordação, mas um laço que une gerações, mantendo vivas as histórias e os legados que moldaram quem somos.<br /><br />

              Inspiramo-nos na sabedoria ancestral dos faraós egípcios, como Ramsés III, que acreditava que a vida após a morte estava garantida ao gravar seu nome em templos e monumentos, permitindo que sua alma recebesse a energia vital sempre que alguém o pronunciasse seu nome.<br />
              Da mesma forma, ao cadastrar GRATUITAMENTE seus familiares no nosso Cemitério Virtual, estará contribuindo para que suas memórias sejam continuamente celebradas e renovadas, permitindo que eles vivam para sempre no coração de todos.<br /><br />

              Este é um convite para que juntos possamos honrar e perpetuar as vidas e histórias daqueles que amamos, garantindo que suas obras e ações continuem a ser lembradas e reverenciadas por muitas gerações.<br />
              Venha fazer parte desta jornada de amor e respeito, onde a memória se torna eterna e o legado perdura para sempre.<br /><br />

              Descubra mais e participe deste espaço de homenagem visitando nosso web app.
            </p>
          </section>
          <section id="services" className="section-services">
            <div className="service-box">
              <img src="imgVela.png" alt="Candle Icon" className="service-icon" />
              <h3>Acenda Velas</h3>
              <p>
                Acenda velas no túmulo dos seus entes queridos, amigos ou ídolos já falecidos
                para confortar seus familiares e/ou da família de seus ídolos, como forma de demonstrar o seu carinho.
              </p>
              <button onClick={handleRedirect}>Acender uma Vela</button>
            </div>
            <div className="service-box">
              <img src="img_oracao.png" alt="Message Icon" className="service-icon" />
              <h3>Escreva Mensagens</h3>
              <p>
                Escreva mensagens no túmulo dos seus entes queridos, amigos ou ídolos já falecidos
                para confortar seus familiares e/ou da família de seus ídolos, como forma de demonstrar o seu carinho.
              </p>
              <button onClick={handleRedirect}>Escrever uma Mensagem</button>
            </div>
            <div className="service-box">
              <img src="imgFlor.png" alt="Flowers Icon" className="service-icon" />
              <h3>Deposite Flores</h3>
              <p>
                Deposite flores no túmulo dos seus entes queridos, amigos ou ídolos já falecidos
                para confortar seus familiares e/ou da família de seus ídolos, como forma de demonstrar o seu carinho.
              </p>
              <button onClick={handleRedirect}>Depositar Flores</button>
            </div>
          </section>
        </main>
        <footer className="App-footer">
          <p>&copy; 2024 YouForever. Todos os direitos reservados.</p>
          <nav>
            <a href="/politicaprivacidade.html">Política de Privacidade</a>
            <a href="/termodeuso.html">Termos de Uso</a>
            <a href="mailto:app@youforever.com.br">Contato</a>
          </nav>
        </footer>
        {isLoading && <div className="loading-overlay">Redirecionando...</div>}
      </div>
    </Router>
  );
}

export default App;
